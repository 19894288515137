import clsx from "clsx"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import BackgroundGradients from "../../../components/BackgroundGradients"
import Layout from "../../../components/Layout"
import PageHeader from "../../../components/PageHeader"
import Seo from "../../../components/Seo"
import * as styles from "./OurStoryPage.module.scss"

const OurStoryPage = () => {
  return (
    <Layout logoColor="light" menuColor="light">
      <Seo title="Story of GritLife" />

      <div>
        <PageHeader
          title="Story of GritLife"
          subtitle="Why we do what we do"
          fullHeight
          color="light"
          backgroundImage="TEMP"
        />

        <div className={styles.pageContent}>
          <div className={styles.block1}>
            <Container fluid="xxl" className="gx-0 gx-md-5 mb-6 mb-md-0">
              <Row className="gx-0 gx-md-5 gy-4">
                <Col md={{ span: 5 }}>
                  <StaticImage src="./story-1.jpg" alt="" layout="fullWidth" />
                </Col>

                <Col md={{ span: 7 }}>
                  <Container
                    fluid
                    className="d-md-flex align-items-start gx-5 gx-md-0"
                  >
                    <div className="me-xxl-5 mb-5 mb-md-0">
                      <h4 className="mb-4 mb-md-7">
                        When nothing was working, do something different.
                      </h4>

                      <p>
                        I've always tried to find patterns and associations to
                        derive simple solutions. This mindset is probably my
                        number one reason that led me down the product
                        development path.
                      </p>
                      <p>
                        When I first became a Dad, I was somewhat naive about
                        children. I often thought, "sure, it might be a bit of
                        work, but how hard it could be?" I often chuckle at how
                        little I knew back then.
                      </p>
                      <p>
                        After my second child was born and my eldest child
                        started displaying behavioural difficulties, I sought
                        help from experts to guide me through these challenging
                        times. Despite thousands of dollars, hundreds of hours
                        and even more classes, books, podcasts, articles, videos
                        or specialist appointments (you name it, I've read it).
                        I was still no closer to a solution for my eldest child.
                      </p>
                    </div>

                    <StaticImage
                      src="./story-2.jpg"
                      alt=""
                      layout="fullWidth"
                      className={styles.image1}
                    />
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>

          <div className={clsx("mb-5", styles.block3)}>
            <Container fluid="xxl" className="gx-0 gx-md-5">
              <Row className="gx-0 gx-md-5">
                <Col xl="7">
                  <Row className="gx-0 gx-md-5">
                    <Col md="7">
                      <StaticImage
                        src="./story-6.jpg"
                        alt=""
                        layout="fullWidth"
                        className="mb-4 mt-5"
                      />

                      <Container fluid="xxl" className="gx-5 gx-md-0">
                        <h3 className="mb-4">Philosophy and mission</h3>
                      </Container>
                    </Col>
                  </Row>

                  <Container fluid="xxl" className="gx-5 gx-md-0">
                    <Row>
                      <Col md="6">
                        <p>
                          So much of our lives are dictated not by the actions
                          of others but by the reactions of ourselves. Whilst we
                          cannot control everything that frustrates us about the
                          world, what we can choose, and influence is how we
                          want these things to affect us.
                        </p>
                        <p>
                          At GritLife, we're on a journey to minimise the daily
                          suffering felt by everyone. We help people by teaching
                          them how to build a growth mindset and reframe how
                          they see the world.
                        </p>
                      </Col>
                      <Col md="5" xl="6">
                        <p>
                          Through our services, people will gain perspective and
                          learn how to micro-mentor themselves through
                          behavioural changes. Thus, relieving some of the
                          stress, frustration, and suffering out of life.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xl="5" className="d-none d-xl-block">
                  <StaticImage src="./story-7.jpg" alt="" layout="fullWidth" />
                </Col>
              </Row>
            </Container>

            <BackgroundGradients
              intensity="high"
              className={styles.backgroundGradients}
            >
              <div className={styles.pink1}></div>
              <div className={styles.green1}></div>
            </BackgroundGradients>
          </div>

          <div className={clsx("mb-5", styles.block4)}>
            <Container fluid="xxl">
              <Row>
                <Col md="4" xl="6">
                  <StaticImage
                    src="./story-4.jpg"
                    alt=""
                    className={styles.image1}
                  />

                  <StaticImage
                    src="./story-5.jpg"
                    alt=""
                    className={styles.image2}
                  />
                </Col>
                <Col md={{ span: 6, offset: 2 }} xl={{ span: 4, offset: 1 }}>
                  <div className={styles.content}>
                    <h3>Our promise</h3>
                    <p>
                      At GritLife we know facilitating behavioural change is a
                      struggle. It's easy to fall back into patterns of bad
                      behaviour or thinking.
                    </p>
                    <p>
                      This is why we promise our members that we're not here to
                      judge or make them feel guilty. We want to help you,
                      always.
                    </p>
                    <p>
                      So regardless of your age, culture, upbringing or anything
                      that makes you unique, at GritLife we're building a
                      service to support you and help steer you through your
                      self-development journey.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <Container fluid="xxl">
            <h3 className="display-3 text-center text-danger mb-0">
              Join us on our journey
            </h3>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default OurStoryPage
