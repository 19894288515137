// extracted by mini-css-extract-plugin
export var pageContent = "OurStoryPage-module--page-content--McdCK";
export var block1 = "OurStoryPage-module--block-1--CC2Me";
export var image1 = "OurStoryPage-module--image-1--PwynP";
export var block2 = "OurStoryPage-module--block-2--dwt5F";
export var headingRow = "OurStoryPage-module--heading-row--FcuWP";
export var title = "OurStoryPage-module--title--u8V84";
export var subtitle = "OurStoryPage-module--subtitle--1PDkA";
export var backgroundGradients = "OurStoryPage-module--background-gradients--QwhcN";
export var yellow1 = "OurStoryPage-module--yellow-1--7oTAD";
export var orange1 = "OurStoryPage-module--orange-1--DVSaC";
export var gradientBackground = "OurStoryPage-module--gradient-background--4IoEW";
export var block3 = "OurStoryPage-module--block-3--igAti";
export var pink1 = "OurStoryPage-module--pink-1--odGy-";
export var green1 = "OurStoryPage-module--green-1--mP9rC";
export var block4 = "OurStoryPage-module--block-4--eT2ZA";
export var image2 = "OurStoryPage-module--image-2--4qGhz";
export var content = "OurStoryPage-module--content--inTVg";